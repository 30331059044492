import { getFloat, roundPrice } from '../../../../../../helper'

export const calculateTotalDiscount = (discountValue, discountType, totalPrice) => {
	const discount = getFloat(discountValue)

	if (discountType === 'CURRENCY') {
		return discount
	} else {
		return roundPrice(totalPrice * discount / 100)
	}
}

export const convert2PresetDiscountOptions = (presetDiscounts) => {
	return presetDiscounts.map(({ id, name, discount }) => ({
		id,
		label: name + ' ' + discount + '%',
		type: 'PRESET_DISCOUNT',
	}))
}

export const convert2ExtraDiscountItem = (id, name, price) => ({
	itemId: id,
	name,
	value: price,
})

export const convert2RestaurantCustomer = ({
	customerNumber = null, name = null, companyName = null,
	street = null, streetNumber = null, city = null, postalCode = null,
	phoneNumber = null, email = null,
}) => {
	return ({
		customerNumber, name, companyName, phoneNumber, email,
		street, streetNumber, city, postalCode,
	})
}
export const convert2DepositPayment = ({
	id, paymentName, additionalPaymentMethod,
}) => {
	return ({
		id, paymentName, method: additionalPaymentMethod, total: 0,
	})
}

export const convert2MarketingCouponItem = (validatedFlyerCoupon, price) => ({
	itemId: validatedFlyerCoupon.id,
	name: validatedFlyerCoupon.name,
	value: price,
})

export const getOptions = (updatePaymentMode, presetDiscountsOptions, customDiscountOption, defaultDiscountOption, flyerCoupon) => {

	if(updatePaymentMode && presetDiscountsOptions.length === 0) {
		return [customDiscountOption]
	} else if (!updatePaymentMode) {
		return [defaultDiscountOption, ...presetDiscountsOptions, customDiscountOption, flyerCoupon]
	} else {
		return [defaultDiscountOption, ...presetDiscountsOptions, customDiscountOption]
	}

}
